<template>
  <div class="container">
    <div class="menu">
      <Menu theme="light" :active-name="showMenu" @on-select="selectMenu">
        <MenuGroup title="菜单栏">
          <MenuItem name="baseInfo">
            <Icon type="ios-information-circle" />
            基本信息
          </MenuItem>
          <MenuItem name="setInfo">
            <Icon type="ios-settings" />
            投票设置
          </MenuItem>
        </MenuGroup>
      </Menu>
    </div>
    <div class="content">
      <div v-show="showMenu == 'baseInfo'">
        <Form :model="formData" :label-width="100" :label-colon="true" inline>
          <FormItem>
            <span slot="label" class="validate">标题:</span>
            <Input
              v-model="formData.title"
              :maxlength="50"
              placeholder="请输入信息名称，不超过50字，20字以内最佳"
              style="width: 500px"
            ></Input>
          </FormItem>
          <FormItem>
            <span slot="label" class="validate">信息来源:</span>
            <Input
              v-model="formData.source"
              :maxlength="20"
              placeholder="请输入信息来源"
              style="width: 500px"
            ></Input>
          </FormItem>
          <FormItem>
            <span slot="label" class="validate">投票时限</span>
            <DatePicker
              type="datetimerange"
              placeholder="请选择讨论开始时间和结束时间"
              v-model="formData.time"
              style="width: 500px"
            ></DatePicker>
          </FormItem>
          <FormItem style="width: 100%">
            <span slot="label" class="validate">投票范围</span>
            <Cascader
              v-if="!hiddenCascader"
              style="width: 500px"
              :data="communityList"
              :load-data="loadCommunity"
              @on-change="getGroupTree"
              v-model="formData.dataScopeCode"
            ></Cascader>
            <Collapse
              v-model="callapseValue"
              style="width: 500px; margin-top: 20px"
              v-if="formData.dataScopeCode.length > 0"
            >
              <Panel name="1">
                选择群范围
                <p slot="content">
                  <span style="display: flex; justify-content: space-around">
                    <Button type="primary" @click="allSelectGroup">全选</Button>
                    <Button type="primary" @click="deleteSelectGroup"
                      >清除选择</Button
                    >
                    <Button type="primary" @click="expandSelectGroup"
                      >全展开</Button
                    >
                    <Button type="primary" @click="shrinkSelectGroup"
                      >全收缩</Button
                    >
                  </span>
                  <Tree
                    :data="groupTreeData"
                    empty-text="暂无群范围"
                    @on-check-change="oneSelectGroupTree"
                    show-checkbox
                    multiple
                  ></Tree>
                </p>
              </Panel>
            </Collapse>
          </FormItem>
          <!-- <iframe
            name="ueditor"
            id="ueditor"
            src="/ueditor/index.html?titlename=议题详细描述（必填）"
            style="width: 100%; height: 500px; border: 0px"
          ></iframe> -->
          <iframe
                name="ueditor"
                id="ueditor"
                src="/mobileeditor/#/index?titlename=议题详细描述（必填）"
                style="width: 100%; height: 500px; border: 0px"
              ></iframe>
        </Form>
      </div>
      <div v-show="showMenu == 'setInfo'" class="modal">
        <div style="margin-bottom: 16px">
          <Button type="primary" icon="md-add" @click="addVoteOption"
            >新增投票项</Button
          >
          <RadioGroup
            v-model="formData.voteType"
            style="margin: 0 10px"
            @on-change="changeVoteType"
          >
            <Radio label="1" border>投一票</Radio>
            <Radio label="2" border>投多票</Radio>
          </RadioGroup>
          <span class="validate" v-if="formData.voteType == '2'"
            >多选设置:</span
          >
          <span style="margin: 0 14px" v-if="formData.voteType == '2'">
            最少选择
            <InputNumber
              :max="9999"
              :min="2"
              v-model="formData.selectMinItem"
            ></InputNumber>
            个选项
          </span>
          <span v-if="formData.voteType == '2'">
            最多选择
            <InputNumber
              :max="9999"
              :min="2"
              v-model="formData.selectMaxItem"
            ></InputNumber>
            个选项
          </span>
          <Tooltip
            content="需填写大于等于2的自然数，两个数字可以相同"
            placement="top"
            transfer
            :max-width="500"
            v-if="formData.voteType == '2'"
          >
            <Icon type="md-help-circle" />
          </Tooltip>
        </div>
        <LiefengTable
          :tableData="tableData"
          :talbeColumns="talbeColumns"
          :hidePage="true"
        ></LiefengTable>
      </div>
    </div>
  </div>
</template>

<script>
import LiefengTable from "@/components/LiefengTable";
export default {
  data() {
    return {
      showMenu: "baseInfo",
      formData: {
        source: parent.vue.loginInfo.userinfo.orgName,
        regionIds: [],
        dataScopeCode: [],
        selectMinItem: null,
        selectMaxItem: null,
        voteType: '1'
      },
      //   讨论范围
      communityList: [],
      hiddenCascader: false, //隐藏级联
      callapseValue: [], //展开收缩群范围
      groupTreeData: [], //群范围

      // 投票设置
      tableData: [
        {
          seq: 1,
          title: '', 
          coverImg: '',
          loading: false
        }
      ],
      talbeColumns: [
        {
          title: "序号",
          key: "seq",
          align: "center",
          width: 120,
          render: (h, params) => {
            return h("InputNumber", {
              props: {
                max: 9999,
                min: 1,
                precision: 0,
                value: params.row.seq,
              },
              on: {
                "on-change": (val) => {
                  this.tableData[params.index].seq = val;
                },
              },
            });
          },
        },
        {
          title: "选项文字内容",
          key: "title",
          align: "center",
          minWidth: 150,
          render: (h, params) => {
            return h("Input", {
              props: {
                maxlength: 50,
                value: params.row.title,
              },
              on: {
                "on-change": (e) => {
                  this.tableData[params.index].title = e.target.value;
                },
              },
            });
          },
        },
        {
          title: "图片",
          key: "coverImg",
          align: "center",
          width: 200,
          render: (h, params) => {
            let result = "";
            if (this.tableData[params.index].coverImg) {
              result = h("img", {
                style: {
                  width: "150px",
                  display: "block",
                  margin: '10px'
                },
                attrs: {
                  src: this.tableData[params.index].coverImg,
                },
              });
            } else {
              result = h(
                "Upload",
                {
                  props: {
                    action: "",
                    name: "file",
                    "before-upload": this.beforeUpload,
                    "show-upload-list": false,
                  },
                },
                [
                  h(
                    "Button",
                    {
                      props: {
                        icon: "ios-cloud-upload-outline",
                        type: "primary",
                        loading: this.tableData[params.index].loading,
                      },
                      on: {
                        click: () => {
                          this.rowIndex = params.index;
                        },
                      },
                    },
                    this.tableData[params.index].loading
                      ? "正在上传"
                      : "上传图片"
                  ),
                ]
              );
            }
            return result;
          },
        },
        {
          title: "操作",
          align: "center",
          width: 100,
          render: (h, params) => {
            return h("Icon", {
              props: {
                type: "ios-trash",
              },
              style: {
                fontSize: "20px",
                cursor: "pointer",
              },
              on: {
                click: () => {
                  if (this.tableData.length == 1) {
                    this.$Message.error({
                      background: true,
                      content: "删除失败，请至少保留一个选项",
                    });
                    return;
                  }
                  this.tableData.splice(params.index, 1);
                },
              },
            });
          },
        },
      ],
      rowIndex: "", //代表图片上传的是哪个选项

      // 保存校验
      validateArr: [
        {
          desc: "请输入标题",
          key: "title",
        },
        {
          desc: "请输入信息来源",
          key: "source",
        },
        {
          desc: "请输入投票时限",
          key: "time",
        },
        {
          desc: "请选择讨论时限",
          key: "time",
        },
        {
          desc: "请选择投票范围",
          key: "dataScopeCode",
        },
        {
          desc: "请选择群范围",
          key: "regionIds",
        },
        {
          desc: "请输入议题详细描述",
          key: "content",
        },
      ]
    };
  },
  methods: {
    // 校验必填
     validateFn() {
      var v_Obj = document.getElementById("ueditor").contentWindow; //获取iframe对象
      this.formData.content = v_Obj.getContent(); //获取富文本内容
      for(let k in this.validateArr) {
        if(this.validateArr[k].key == 'time' && (this.formData[this.validateArr[k].key][0] == '' || this.formData[this.validateArr[k].key][1] == '')) {
          this.$Message.error({
            background: true,
            content: this.validateArr[k].desc
          })
          return;
        }else if(this.formData[this.validateArr[k].key] === undefined || this.formData[this.validateArr[k].key] === null || this.formData[this.validateArr[k].key] === '' || this.formData[this.validateArr[k].key].length == 0) {
          this.$Message.error({
            background: true,
            content: this.validateArr[k].desc
          })
          return;
        }
      }
      if(this.formData.voteType == '2') {
        if(!this.formData.selectMinItem) {
          this.$Message.error({
            background: true,
            content: "请设置最少需选择的选项"
          })
          return;
        }else if(!this.formData.selectMaxItem){
          this.$Message.error({
            background: true,
            content: "请设置最多可选择的选项"
          })
          return;
        }
      }
      for (let k in this.tableData) {
        if (!this.tableData[k].seq) {
          this.$Message.error({
            background: true,
            content: "请设置第" + (k + 1) + "个选项的序号",
          });
          return;
        } else if (!this.tableData[k].title) {
          this.$Message.error({
            background: true,
            content: "请设置第" + (k + 1) + "个选项的选项文字内容",
          });
          return;
        }
      }
      if(this.formData.voteType == '2' && this.tableData.length < this.formData.selectMinItem) {
        this.$Message.error({
            background: true,
            content: "至少设置" + this.formData.selectMinItem + "个投票项",
          });
          return;
      }
      this.save();
    },
    save() {
       this.$Message.loading({
        content: "正在保存，请稍等...",
        duration: 0
      })
      let voteItemRos = this.tableData.map(item => {
        return {
          seq: item.seq,
          coverImg: item.coverImg,
          title: item.title
        }
      })
      this.$post('/sendmsg/pc/chat/discussBusiness/addVoteBusiness',{
        businessType: '2',
         ...this.formData,
          custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
          startTime: this.$core.formatDate(new Date(this.formData.time[0]),"yyyy-MM-dd hh:mm:ss"),
          endTime: this.$core.formatDate(new Date(this.formData.time[1]),"yyyy-MM-dd hh:mm:ss"),
          modifier: parent.vue.loginInfo.userinfo.realName,
          oemCode: parent.vue.oemInfo.oemCode,
          orgCode: parent.vue.loginInfo.userinfo.orgCode,
          regionIds: this.formData.regionIds,
          dataScopeCode: this.formData.dataScopeCode[this.formData.dataScopeCode.length -1].split('-')[this.formData.dataScopeCode[this.formData.dataScopeCode.length -1].split('-').length -1],
          time: null,
          voteItemRos: voteItemRos,
      },{"Content-Type": "application/json"}).then(res => {
        this.$Message.destroy();
        if(res.code == 200) {
          this.$Message.success({
            background: true,
            content: "保存成功"
          });
          this.$emit('saveSuccess')
        }else {
           this.$Message.error({
            background: true,
            content: res.desc
          });
        }
      }).catch(err => {
        console.log(err);
        this.$Message.destroy();
        this.$Message.error({
          background: true,
          content: "保存失败，请联系管理员处理"
        })
      })
    },

    // 新增投票项
    addVoteOption() {
      this.tableData.push({
        seq:
          this.tableData.length && this.tableData[this.tableData.length - 1].seq
            ? this.tableData[this.tableData.length - 1].seq + 1
            : this.tableData.length + 1,
        title: "",
        coverImg: "",
        loading: false
      });
    },
    // 切换投票方式单选按钮
    changeVoteType() {
      this.formData.selectMinItem = null;
      this.formData.selectMaxItem = null;
    },
    // 投票项上传图片——之前
    beforeUpload(file) {
      let suffix = file.name.substr(file.name.lastIndexOf("."));
      if (suffix != ".jpg" && suffix != ".png" && suffix != ".jpeg") {
        this.$Notice.warning({
          title: "上传文件格式错误！",
          desc:
            "文件 " + file.name + " 上传失败, 请上传jpg,png,jpeg格式的图片！",
        });
        return false;
      }
      //改变上传图片按钮——正在上传
      this.$set(this.tableData[this.rowIndex], "loading", true);

      this.$get("/datamsg/api/common/file/getWebPolicy?bucketName=liefeng")
        .then((res) => {
          if (res.code == 200 && res.data) {
            let now = this.$core.randomString(8);
            let uploadImageData = {
              name: now + file.name,
              host: res.data.host,
              key: res.data.key + now + file.name,
              policy: res.data.encodedPolicy,
              OSSAccessKeyId: res.data.accessId,
              success_action_status: "200",
              signature: res.data.postSignature,
            };
            let formData = new FormData();
            formData.append("name", uploadImageData.name);
            formData.append("host", uploadImageData.host);
            formData.append("key", uploadImageData.key);
            formData.append("policy", uploadImageData.policy);
            formData.append("OSSAccessKeyId", uploadImageData.OSSAccessKeyId);
            formData.append(
              "success_action_status",
              uploadImageData.success_action_status
            );
            formData.append("signature", uploadImageData.signature);
            formData.append("file", file);
            this.$.ajax({
              type: "POST",
              contentType: false,
              processData: false,
              url: uploadImageData.host,
              data: formData,
              headers:{openId:window.sessionStorage.getItem("openId"),Authorization:window.sessionStorage.getItem('accessToken')},
              success: () => {
                this.$set(this.tableData[this.rowIndex],'coverImg',uploadImageData.host + uploadImageData.key);
                
                this.$Notice.success({
                  title: "上传成功",
                  desc: "文件 " + file.name + " 已成功上传",
                });
              },
              error: () => {
                this.$Notice.warning({
                  title: "上传失败！",
                  desc: "文件 " + file.name + " 上传失败！",
                });
              },
            });
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$Message.error({
            background: true,
            content: "上传功能异常，请联系管理员处理",
          });
        });
      return false;
    },
    //   选择菜单栏
    selectMenu(name) {
      if (name == "baseInfo") {
        this.$emit("changeBackNextText", "下一步");
      } else {
        this.$emit("changeBackNextText", "上一步");
      }
      this.showMenu = name;
    },
    // 动态加载街道/社区
    loadCommunity(item, callback) {
      item.loading = true;
      let arrCode = item.value.split("-");
      this.$get("/old/api/pc/staff/selectCascadeDataScope", {
        orgCode: arrCode[arrCode.length - 1],
        oemCode: parent.vue.oemInfo.oemCode,
        orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
      }).then((res) => {
        if (res.code == 200) {
          if (!item.index) {
            if (res.dataList.length == 1) {
              //如果只有一项，默认选中
              this.formData.dataScopeCode.push(item.orgPath);
            }
            item.children = res.dataList.map((item) => {
              //街道
              return {
                value: item.orgPath,
                label: item.orgName,
                orgLevel: item.orgLevel,
                loading: false,
                children: [],
                index: 1,
              };
            });
          } else {
            if (res.dataList.length == 1) {
              //如果只有一项，默认选中,并且隐藏掉级联
              this.formData.dataScopeCode.push(item.orgPath);
              this.hiddenCascader = true;
            } else {
              this.hiddenCascader = false;
            }
            item.children = res.dataList.map((item) => {
              //社区
              return {
                value: item.orgPath,
                label: item.orgName,
                orgLevel: item.orgLevel,
              };
            });
          }
          item.loading = false;
          callback();
        }
      });
    },
    // 获取区
    getPulishData(code) {
      this.$get("/old/api/pc/staff/selectCascadeDataScope", {
        orgCode: code,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
      }).then((res) => {
        if (res.code == 200) {
          if (res.dataList.length == 1) {
            //如果只有一项，默认选中
            this.formData.dataScopeCode.push(item.orgPath);
          }
          this.communityList = res.dataList.map((item) => {
            return {
              value: item.orgPath,
              label: item.orgName,
              orgLevel: item.orgLevel,
              loading: false,
              children: [],
            };
          });
        }
      });
    },
    // 获取群范围tree
    getGroupTree(code) {
      let orgCode = code[code.length - 1].split("-")[
        code[code.length - 1].split("-").length - 1
      ];
      this.$get("/sendmsg/pc/chat/discussBusiness/getRegionTree", {
        businessId: this.infoId,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: orgCode,
      }).then((res) => {
        if (res.code == 200 && res.dataList) {
          this.groupTreeData = res.dataList;
        } else if (res.code == 200 && !res.dataList) {
          this.$Message.error({
            background: true,
            content: "未查询到您的群范围",
          });
        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
      });
    },
    // 单次选中群范围
    oneSelectGroupTree(arr) {
      this.formData.regionIds = [];
      this.selectGroupTree(arr);
    },
    // 全选，单次选中时，push到regionIds
    selectGroupTree(arr) {
      arr.map((item) => {
        if (!item.children || item.children.length == 0) {
          this.formData.regionIds.push(item.id);
          this.formData.regionIds = Array.from(
            new Set(this.formData.regionIds)
          );
        } else {
          this.selectGroupTree(item.children);
        }
      });
    },
    // 封装全选和反选群范围
    allDeleteSelectGroup(tree, checkVal, expandVal) {
      const result = [];
      tree.forEach((item) => {
        let id = item.id;
        let parentId = item.parentId;
        let expand = expandVal !== "" ? expandVal : item.expand;
        let checked = checkVal !== "" ? checkVal : item.checked;
        let title = item.title;
        let code = item.code;
        let children = item.children;
        // 如果有子节点，递归
        if (children && children.length != 0) {
          children = this.allDeleteSelectGroup(children, checkVal, expandVal);
        }
        result.push({ expand, code, checked, title, children, id, parentId });
      });
      return result;
    },
    // 全选群范围
    allSelectGroup() {
      this.formData.regionIds = [];
      this.groupTreeData = this.allDeleteSelectGroup(
        this.groupTreeData,
        true,
        ""
      );
      this.selectGroupTree(this.groupTreeData);
    },
    // 反选群范围
    deleteSelectGroup() {
      this.groupTreeData = this.allDeleteSelectGroup(
        this.groupTreeData,
        false,
        ""
      );
      this.formData.regionIds = [];
    },
    expandSelectGroup() {
      this.groupTreeData = this.allDeleteSelectGroup(
        this.groupTreeData,
        "",
        true
      );
    },
    shrinkSelectGroup() {
      this.groupTreeData = this.allDeleteSelectGroup(
        this.groupTreeData,
        "",
        false
      );
    },
  },
  created() {
    sessionStorage.setItem('NODEENV',process.env.NODE_ENV)
    // 获取广州市的区
    this.getPulishData(4401);
  },
  components: {
    LiefengTable,
  },
};
</script>
    
<style scoped lang='less'>
.container {
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 125px);
  /deep/.menu {
    height: 100%;
    .ivu-menu {
      height: 100%;
      width: 220px !important;
    }
  }
  .content {
    padding: 0 20px;
    overflow: auto;
    flex: 1;
    height: 100%;
  }
}
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
.modal {
  /deep/.ivu-table-wrapper {
    height: calc(100vh - 173px);
    /deep/.ivu-table {
      overflow: auto;
    }
  }
}
</style>